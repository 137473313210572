.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* .comment-div .ant-row-bottom{
  width: 125%;
} */

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.phone-ant-input {
  display: flex;
  align-items: center;
}

.phone-ant-input .PhoneInputInput {
  flex: 1;
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  padding: 8.5px 11px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 0.625rem;
  transition: all 0.3s ease 0s;
}

.buyerDocuments .ant-image {
  margin-right: 8px;
}

.truncate-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.phone-ant-input {
  display: flex;
  align-items: center;
}

.phone-ant-input .PhoneInputInput {
  flex: 1;
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  padding: 8.5px 11px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 0.625rem;
  transition: all 0.3s ease 0s;
}

.PhoneInputCountry img {
  height: 13px !important;
}

.PhoneInputCountry select {
  width: 18px;
  border: none;
}

.PhoneInputCountry {
  display: flex;
  flex-direction: row-reverse;
}

.web-banner .ant-modal-content{
  width: 800px !important;
}

.web-banner .ant-modal-body{
  height: 400px !important;
}

.reactEasyCrop_Container {
  width: 700px !important;
  height: 500px !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoom-modal .ant-modal-content {
  position: relative;
  background-color: transparent !important;
  background-clip: padding-box;
  border: 0px;
  /* border-radius: 0.625rem; */
  box-shadow: none !important;
  pointer-events: auto;
}

.zoom-modal .ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: 0px 0px;
  border-top: none !important;
  border-radius: 0px 0px 0.625rem 0.625rem;
}